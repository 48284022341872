<template>
  <div class="page bg-white" id="reorganize">
    <div>
      <el-tabs size="small" type="card" v-model="selectTab" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                     :name="item.value"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="contens">
      <!--基本信息-->
      <div v-show="selectTab == 0">
        <el-row>
          <el-form :model="ruleInformation" ref="ruleInformation" label-width="200px"
                   size="small" class="demo-ruleForm">
            <div class="minTitle">分类与编号</div>
            <el-col :span="22">
              <el-form-item label="藏品名称" prop="collectionName"
                            :rules="[{ required: true, message: '请输入藏品名称', trigger: 'blur' }]">
                <el-input :disabled="inputDisable" :rows="1" type="textarea"
                          v-model.trim="ruleInformation.collectionName"
                          placeholder="请输入藏品名称(限300字)"
                          maxlength="300" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="藏品类型" prop="collectionType"
                            :rules="[{ required: true, message: '请选择藏品类型', trigger: 'change' }]">
                <el-select :disabled="inputDisable" v-model="ruleInformation.collectionType"
                           @change="collectionTypeSelect"
                           placeholder="请选择藏品类型"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in typeList"
                      :key="item.id"
                      :label="item.collectionTypeName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="级别" prop="collectionLevel"
                            :rules="[{ required: true, message: '请选择藏品级别', trigger: 'change' }]">
                <el-select :disabled="inputDisable" v-model="ruleInformation.collectionLevel"
                           placeholder="请选择藏品级别"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in leaveList"
                      :key="item.level"
                      :label="item.levelName"
                      :value="item.level">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="藏品分类" prop="archivesBasicDataId"
                            :rules="[{ required: true, message: '请选择藏品分类', trigger: 'change' }]">
                <el-select :disabled="inputDisable" v-model="ruleInformation.archivesBasicDataId"
                           placeholder="请选择藏品分类"
                           style="width: 100%"
                           @change="(value)=>{selectCascader('',1)}"
                           clearable>
                  <el-option
                      v-for="item in classList"
                      :key="item.id"
                      :label="item.archivesBasicName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="二级分类" prop="twoType">
                <el-select :disabled="inputDisable" v-model="ruleInformation.twoType"
                           placeholder="请选择二级分类"
                           style="width: 100%"
                           @change="(value)=>{selectCascader('',2)}"
                           clearable>
                  <el-option
                      v-for="item in classListSon"
                      :key="item.id"
                      :label="item.archivesBasicName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="是否鉴定" prop="identified">
                <el-select :disabled="inputDisable" v-model="ruleInformation.identified"
                           placeholder="请选择" style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in identifyList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="butType != 5 && butType != 6" :span="11">
              <el-form-item label="辅助账编号" prop="helpNum">
                <el-input :disabled="inputDisable" v-model="ruleInformation.helpNum"
                          placeholder="请输入藏品编号(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col
                v-if="butType != 5 && butType != 6 && ruleInformation.collectionType && ruleInformation.collectionType == 1"
                :span="11">
              <el-form-item label="总账编号" prop="generalNum">
                <el-input :disabled="inputDisable" v-model="ruleInformation.generalNum"
                          placeholder="请输入藏品编号(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="完残程度" prop="integrity"
                            :rules="[{ required: true, message: '请选择完残程度', trigger: 'change' }]">
                <el-select :disabled="inputDisable" v-model="ruleInformation.integrity"
                           placeholder="请选择完残程度"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in integrityList"
                      :key="item.id"
                      :label="item.levelName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="库存状态" prop="cStoreState"
                            :rules="[{ required: true, message: '请选择库存状态', trigger: 'change' }]">
                <el-select
                    :disabled="typePage == 'ledger' ? true : typePage == 'auxiliaryAccount' ? true : inputDisable"
                    v-model="ruleInformation.cStoreState"
                    placeholder="请选择库存状态"
                    style="width: 100%"
                    clearable>
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('cStore_state')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="完残情况" prop="integrityInfo">
                <el-input :disabled="inputDisable" v-model="ruleInformation.integrityInfo"
                          placeholder="请输入完残情况(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-form>

          <el-form v-show="ruleInformation.collectionType == 3" :model="logoutInfo" ref="logoutInfo"
                   label-width="200px"
                   size="small" class="demo-ruleForm">
            <el-col :span="24">
              <div class="minTitle">复仿制品信息</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="仿品号" prop="copyNum">
                <el-input :disabled="inputDisable" v-model="logoutInfo.copyNum"
                          placeholder="请输入仿品号(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="经办人" prop="handledBy">
                <el-input :disabled="inputDisable" v-model="logoutInfo.handledBy"
                          placeholder="请输入经办人(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="复刻藏品名称" prop="copyName">
                <el-input :disabled="inputDisable" v-model="logoutInfo.copyName"
                          placeholder="请输入复刻藏品名称(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="原名" prop="oldName">
                <el-input :disabled="inputDisable" v-model="logoutInfo.oldName"
                          placeholder="请输入原名(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="复仿制类型" prop="copyType">
                <el-select
                    v-model="logoutInfo.copyType"
                    clearable
                    @clear="logoutInfo.copyType = null"
                    :disabled="inputDisable"
                    placeholder="请选择复仿制类型"
                    style="width: 100%"
                >
                  <el-option
                      v-for="item in this.$dictUtils.getDictList('copy_type')"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="复仿制方式" prop="copyWay">
                <el-input :disabled="inputDisable" v-model="logoutInfo.copyWay"
                          placeholder="请输入复仿制方式(限100字)"
                          maxlength="100" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="复仿制日期" prop="copyDate">
                <el-date-picker
                    v-model="logoutInfo.copyDate"
                    :disabled="inputDisable"
                    type="date"
                    style="width: 100%"
                    value-format="yyyy-MM-dd"
                    clearable
                    placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="复仿制品描述" prop="copyDesc">
                <el-input :disabled="inputDisable" :rows="3" type="textarea"
                          v-model.trim="logoutInfo.copyDesc"
                          placeholder="请输入复仿制品描述(限500字)"
                          maxlength="500" clearable></el-input>
              </el-form-item>
            </el-col>
          </el-form>


          <!--元数据-->
          <el-form size="small" :model="inputForm" :rules="rules" ref="inputForm"
                   label-width="200px" :disabled="inputDisable">
            <el-col :span="24">
              <div class="minTitle">基础</div>
              <div v-loading="loading"
                   v-if="ruleInformation.archivesBasicDataId && ruleInformation.archivesBasicDataId.length != 0">
                <el-col
                    :span="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length && item.maxLength && item.maxLength > 100 ? 22 : 11"
                    v-for="(item, index) in config" :key="index">
                  <el-form-item :label="item.forName ? item.forName : item.basicName + '：'"
                                :prop="item.code">
                    <!--字符-->
                    <el-input :type="item.maxLength && item.maxLength > 100 ? 'textarea': ''"
                              v-model.trim="inputForm[item.code]" :maxlength="item.maxLength || 300"
                              :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                              clearable
                              v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
                    <!--数值、浮点数-->
                    <el-input v-model.trim="inputForm[item.code]" maxlength="8"
                              @input="getNumType(item)"
                              @blur="verifyNum(item)"
                              :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                              clearable
                              v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
                    <!--下拉选择-->
                    <el-select class="w100i" v-model="inputForm[item.code]"
                               :multiple="item.archivesBasicMultiselectList[0].selectBy == 1"
                               collapse-tags
                               :placeholder="item.forName ? '请选择' + item.forName : '请选择' + item.basicName"
                               clearable
                               v-if="item.archivesBasicMultiselectList.length">
                      <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                                 :label="v.archivesBasicValue"
                                 :value="v.archivesBasicValue"></el-option>
                    </el-select>
                    <!--时间-->
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd'">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyyMMdd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd'">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </div>
              <div v-else>
                <div class="baseTip">请先选择藏品分类</div>
              </div>
            </el-col>
          </el-form>
        </el-row>
      </div>

      <!--图片-->
      <div v-if="selectTab == 1">
        <pictures ref="pictures" :typePage="typePage" :id="id"></pictures>
      </div>

      <!--三维模型-->
      <div v-if="selectTab == 7">
        <threes ref="threes" :typePage="typePage" :id="id"></threes>
      </div>

      <!--音频-->
      <div v-if="selectTab == 2">
        <audio-frequency ref="audioFrequency" :typePage="typePage" :id="id"></audio-frequency>
      </div>

      <!--视频-->
      <div v-if="selectTab == 3">
        <video-s ref="videoS" :typePage="typePage" :id="id"></video-s>
      </div>

      <!--文献资料-->
      <div v-if="selectTab == 4">
        <literatures ref="literatures" :typePage="typePage" :id="id" :selectTab="selectTab" @gotoOtherDetail="gotoOtherDetail"></literatures>
      </div>

      <!--鉴定资料-->
      <div v-if="selectTab == 5">
        <appraisals ref="appraisals" :typePage="typePage" :id="id" :selectTab="selectTab" @gotoOtherDetail="gotoOtherDetail"></appraisals>
      </div>

      <!--证明资料-->
      <div v-show="selectTab == 6">
        <proves ref="proves" :typePage="typePage" :id="id" :inputDisable="inputDisable" :butType="butType"></proves>
      </div>

      <!--修复资料-->
      <div v-if="selectTab == 8">
        <repairs ref="repairs" :typePage="typePage" :id="id" :selectTab="selectTab" @gotoOtherDetail="gotoOtherDetail"></repairs>
      </div>

      <!--消毒资料-->
      <div v-if="selectTab == 9">
        <disinfects ref="disinfects" :typePage="typePage" :id="id" :selectTab="selectTab" @gotoOtherDetail="gotoOtherDetail"></disinfects>
      </div>

      <!--装裱资料-->
      <div v-if="selectTab == 10">
        <mountings ref="mountings" :typePage="typePage" :id="id" :selectTab="selectTab" @gotoOtherDetail="gotoOtherDetail"></mountings>
      </div>

      <!--保护资料-->
      <div v-if="selectTab == 11">
        <protect ref="protect" :id="id" :selectTab="selectTab" @gotoOtherDetail="gotoOtherDetail"></protect>
      </div>

      <!--事故资料-->
      <div v-if="selectTab == 12">
        <accident ref="accident" :id="id" :selectTab="selectTab" @gotoOtherDetail="gotoOtherDetail"></accident>
      </div>

      <!--出版物数据-->
      <div v-if="selectTab == 13">
        <publications key="1" ref="publications" :id="id" listType="4" moduleId="946050391827546112"></publications>
      </div>

      <!--文件资料-->
      <div v-if="selectTab == 14">
        <publications key="2" ref="publications" :id="id" listType="6" moduleId="946050511168077824"></publications>
      </div>

      <!--其他资源-->
      <div v-if="selectTab == 15">
        <publications key="3" ref="publications" :id="id" listType="7" moduleId="946050537948708864"></publications>
      </div>


    </div>
    <div v-if="butType != 0 && butType != 2 && butType != 4 && butType != 5 && butType != 6 && butType != 9"
         class="text_center">
      <el-button size="small" v-if="!see" type="primary" @click="switchData(1)">上一条</el-button>
      <el-button size="small" v-if="!see" type="primary" @click="switchData(0)">下一条</el-button>
      <el-button size="small"
                 v-show="typePage != 'index'"
                 v-if="butType == 1 && ruleInformation.processState == 1 && see != 1 || butType == 3 && ruleInformation.processState == 1 && see != 1 || butType == 8 && typePage != 'museum' && ruleInformation.processState == 1 && see != 1"
                 type="primary"
                 @click="thread(3)">通过
      </el-button>
      <el-button size="small"
                 v-show="typePage != 'index'"
                 v-if="butType == 1 && ruleInformation.processState == 1 && see != 1 || butType == 3 && ruleInformation.processState == 1 && see != 1 || butType == 8 && typePage != 'museum' && ruleInformation.processState == 1 && see != 1"
                 type="danger"
                 @click="thread(2)">驳回
      </el-button>
      <el-button size="small" @click="returnPage(1)">关闭</el-button>
    </div>
    <div v-else-if="!inputDisable" class="text_center">
      <el-button size="small" @click="returnPage(1)">取消</el-button>
      <el-button size="small" type="primary" @click="pagePreview()">预览</el-button>
      <!--            <el-button v-if="butType == 5" size="small" type="primary" @click="saveColl()">保存</el-button>-->
    </div>
    <div v-else class="text_center">
      <el-button size="small" type="primary" @click="backDate()">上一步</el-button>
      <el-button v-if="butType == 5 || butType == 6" size="small" type="primary" @click="saveColl()">保存
      </el-button>
      <el-button v-if="butType != 5 && butType != 6 && typePage != 'ledger' && typePage != 'auxiliaryAccount'"
                 size="small"
                 type="primary" @click="saveAsDraft(2)">保存为草稿
      </el-button>
      <el-button v-if="butType != 5 && butType != 6" size="small" type="primary" @click="saveAsDraft(1)">提交
      </el-button>
    </div>
    <reviewSuggestions ref="suggestions" @updataStatus="returnPage(1)"></reviewSuggestions>
    <see-three-file ref="seethree"></see-three-file>
  </div>
</template>

<script>
import resumeUpload from "@/components/Upload/resumeUpload.vue";
import pdf from "vue-pdf";
import reviewSuggestions from "@/views/modules/collection/accounts/module/reviewSuggestions.vue";
import treeSelect from "@/components/treeSelect/treeSelect.vue";
import SelectTree from "@/components/treeSelect/treeSelect.vue";
import {Base64} from 'js-base64'
import SeeThreeFile from "@/views/modules/collection/accounts/module/seeThreeFile.vue";
import Pictures from "@/views/modules/collection/accounts/detailModule/pictures.vue";
import AudioFrequency from "@/views/modules/collection/accounts/detailModule/audioFrequency.vue";
import VideoS from "@/views/modules/collection/accounts/detailModule/videoS.vue";
import Literatures from "@/views/modules/collection/accounts/detailModule/literatures.vue";
import Threes from "@/views/modules/collection/accounts/detailModule/threes.vue";
import Appraisals from "@/views/modules/collection/accounts/detailModule/appraisals.vue";
import Proves from "@/views/modules/collection/accounts/detailModule/proves.vue";
import Repairs from "@/views/modules/collection/accounts/detailModule/repairs.vue";
import Disinfects from "@/views/modules/collection/accounts/detailModule/disinfects.vue";
import Mountings from "@/views/modules/collection/accounts/detailModule/mountings.vue";
import Protect from "@/views/modules/collection/accounts/detailModule/protect.vue";
import Accident from "@/views/modules/collection/accounts/detailModule/accident.vue";
import Publications from "@/views/modules/collection/accounts/detailModule/publications.vue";

export default {
  name: "addCollection",
  components: {
    Publications,
    Accident,
    Protect,
    Mountings,
    Disinfects,
    Repairs,
    Proves,
    Appraisals,
    Threes,
    Literatures,
    VideoS,
    AudioFrequency, Pictures, SeeThreeFile, SelectTree, resumeUpload, pdf, reviewSuggestions, treeSelect
  },
  data() {
    return {
      selectTab: '0',
      tabsList: [],
      typeList: [],
      leaveList: [],
      leaveList2:[],

      //元数据字段
      config: [],
      inputForm: {},
      rules: {},
      loading: false,

      id: '',
      ruleInformation: {
        id:'',
        archivesBasicDataId: '',
        twoType: '',
        collectionName: '',
        collectionType: '',
        collectionLevel: '',
        cStoreState:'',
        identified: '',
        helpNum: '',
        generalNum: '',
        integrity: '',
        archivesData: {},
        integrityInfo: '',
      },

      logoutInfo: {
        copyNum: '',
        handledBy: '',
        copyName: '',
        oldName: '',
        copyType: null,
        copyWay: '',
        copyDate: '',
        copyDesc: '',
        cStoreState: '',
      },

      classList: [],
      classListSon: [],
      identifyList: [
        {
          label: '是',
          value: '0',
        },
        {
          label: '否',
          value: '1',
        },
      ],
      integrityList: [
        {
          id: '残缺',
          levelName: '残缺',
        },
        {
          id: '基本完整',
          levelName: '基本完整',
        },
        {
          id: '完整',
          levelName: '完整',
        },
        {
          id: '严重残缺',
          levelName: '严重残缺',
        },
      ],

      typePage: '', //审核页面的标识(通过和驳回时用)
      inputDisable: false,//是否禁用所有输入框
      butType: '0', //页面按钮状态 0新增 1普通详情页 2修改 3藏品登记审核详情页 4重新提交 5征集登记新增 6征集登记修改 7征集登记详情 8清单、征集藏品入馆详情 9征集藏品入馆修改
      programExit: false,//是否用代码退出
      see: false,

      registrationSearch: {},
      pageAllData: {},//征集数据

      returnPageStatus: null,//判断是否是点返回或退出按钮
    }
  },

  mounted() {
    //页面按钮控制
    this.butType = this.$route.query.butType
    this.see = this.$route.query.see
    this.typePage = this.$route.query.typePage
    //更改面包屑标题
    this.setPageTitle()
    if (this.butType != 0 && this.butType != 2 && this.butType != 4 && this.butType != 5 && this.butType != 6 && this.butType != 9) {
      this.inputDisable = true
    }
    //查询藏品级别和藏品类型
    this.getSelectData()

    if (this.$route.query.id) {
      this.id = this.$route.query.id
      //藏品分类
      this.getTreeData()
      this.$nextTick(() => {
        this.$refs.proves.getPicture()
      })
    } else if (this.butType != 6 && this.butType != 7) {
      this.getTreeData()
    }

    if (this.butType == 6 || this.butType == 7) {
      this.pageAllData = JSON.parse(sessionStorage.getItem('pageAllData'))
      this.getTreeData(3)
    }
    this.setTabData()

    //详情查看的返回缓存
    this.getReturnSearchDate()
  },

  methods: {
    // 获取藏品分类
    getTreeData(type) {
      this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
        if (res.status) {
          this.classList = res.data
          this.$nextTick(() => {
            if (type) {
              this.ruleInformation = this.pageAllData.allDataList[this.pageAllData.setindex]
              this.getZhengJi()
            }
            if (this.id) {
              this.getDataDetail()
            } else {
              this.selectCascader(2)
            }
          }, 200)
        }
      })
    },

    getReturnSearchDate(){
      let listSearchCollDetail = JSON.parse(sessionStorage.getItem('listSearchCollDetail'))
      if (listSearchCollDetail) {
        this.selectTab = listSearchCollDetail.selectTab;
        sessionStorage.removeItem('listSearchCollDetail')
        setTimeout(() => {
          if (listSearchCollDetail.selectTab == 4) { //文献
            this.$refs.literatures.inputForm = listSearchCollDetail.inputForm
            this.$refs.literatures.current = listSearchCollDetail.current
            this.$refs.literatures.size = listSearchCollDetail.size
            this.$refs.literatures.getDataList()
          }
          if (listSearchCollDetail.selectTab == 5) { //鉴定资料
            this.$refs.appraisals.inputForm = listSearchCollDetail.inputForm
            this.$refs.appraisals.current = listSearchCollDetail.current
            this.$refs.appraisals.size = listSearchCollDetail.size
            this.$refs.appraisals.getDataList()
          }
          if (listSearchCollDetail.selectTab == 8) { //修复资料
            this.$refs.repairs.inputForm = listSearchCollDetail.inputForm
            this.$refs.repairs.current = listSearchCollDetail.current
            this.$refs.repairs.size = listSearchCollDetail.size
            this.$refs.repairs.getDataList()
          }
          if (listSearchCollDetail.selectTab == 9) { //消毒资料
            this.$refs.disinfects.inputForm = listSearchCollDetail.inputForm
            this.$refs.disinfects.current = listSearchCollDetail.current
            this.$refs.disinfects.size = listSearchCollDetail.size
            this.$refs.disinfects.getDataList()
          }
          if (listSearchCollDetail.selectTab == 10) { //装裱资料
            this.$refs.mountings.inputForm = listSearchCollDetail.inputForm
            this.$refs.mountings.current = listSearchCollDetail.current
            this.$refs.mountings.size = listSearchCollDetail.size
            this.$refs.mountings.getDataList()
          }
          if (listSearchCollDetail.selectTab == 11) { //保护资料
            this.$refs.protect.inputForm = listSearchCollDetail.inputForm
            this.$refs.protect.current = listSearchCollDetail.current
            this.$refs.protect.size = listSearchCollDetail.size
            this.$refs.protect.getDataList()
          }
          if (listSearchCollDetail.selectTab == 12) { //事故资料
            this.$refs.accident.inputForm = listSearchCollDetail.inputForm
            this.$refs.accident.current = listSearchCollDetail.current
            this.$refs.accident.size = listSearchCollDetail.size
            this.$refs.accident.getDataList()
          }
        }, 200)
      }
    },

    //征集新增修改详情
    getZhengJi(data) {
      if (data) {
        this.ruleInformation = data
        this.getTreeData()
      }
      if (this.ruleInformation.id) {
        this.id = this.ruleInformation.id
        this.$nextTick(() => {
          this.$refs.proves.getPicture()
          this.setTabData()
        })
      } else {
        this.selectCascader(2)
        if (this.ruleInformation.files) {
          this.$refs.proves.proveDataList = this.ruleInformation.files
          this.$refs.proves.$refs['resume-upload'].setOldFile(this.ruleInformation.files)
        }
      }
      this.ruleInformation.collectionLevel = this.ruleInformation.collectionLevel * 1
      this.ruleInformation.collectionType = this.ruleInformation.collectionType * 1
      this.ruleInformation.cStoreState = this.ruleInformation.cStoreState + ''

    },

    //获取详情
    getDataDetail(type) {
      let urlwq = this.api.collection.collectiondataGetById
      if (this.typePage == 'ledgerReview' || this.typePage == 'accountExamine') {
        urlwq = this.api.collection.derreviewCollectiondataGetById
      }
      if (this.typePage == 'InOroutStorage') {
        urlwq = this.api.collection.collectionwarehouseorderGetById
      }
      this.$axios(urlwq + this.id, {}, 'get').then((res) => {
        if (res.status) {
          // Object.keys(this.ruleInformation).forEach((key) => {
          //   this.ruleInformation[key] = res.data[key]
          // })
          this.id = res.data.id
          this.ruleInformation = res.data
          // this.$set(this.ruleInformation,'archivesDataJSONObject',res.data.archivesDataJSONObject)
          this.ruleInformation.archivesBasicDataId = res.data.oneType
          this.ruleInformation.collectionLevel = this.ruleInformation.collectionLevel * 1
          this.ruleInformation.identified = this.ruleInformation.identified
          this.ruleInformation.collectionType = this.ruleInformation.collectionType * 1
          this.ruleInformation.cStoreState = this.ruleInformation.cStoreState + ''
          this.selectCascader(1)
          if (res.data.collectionDataCopy) {
            this.logoutInfo = res.data.collectionDataCopy
          } else {
            this.logoutInfo = {
              copyNum: '',
              handledBy: '',
              copyName: '',
              oldName: '',
              copyType: null,
              copyWay: '',
              copyDate: '',
              copyDesc: '',
              cStoreState: '',
            }
          }
        }
      })
    },

    //藏品分类级联选择 type 1详情需要给数据 2征集
    selectCascader(type, num) {
      if (this.ruleInformation.archivesBasicDataId) {
        if (num == 1) {
          this.ruleInformation.twoType = ''
        }
        this.classList.forEach(item => {
          if (item.id == this.ruleInformation.archivesBasicDataId) {
            this.classListSon = item.children
          }
        })
        if (this.ruleInformation.twoType) {
          this.getMetadata(this.ruleInformation.twoType, type)
        } else {
          this.getMetadata(this.ruleInformation.archivesBasicDataId, type)
        }
      } else {
        this.ruleInformation.twoType = ''
        this.classListSon = []
        this.config = []
        this.inputForm = {}
        this.rules = {}
      }
    },

    getMetadata(archivesBasicDataId, type) {
      this.loading = true
      this.$axios(this.api.collection.basicdataGetById + '/' + archivesBasicDataId).then(data => {
        if (data.status) {
          this.config = data.data.archivesBasicDataGroup
          this.config.forEach(item => {
            this.$set(this.inputForm, item.code, null)
            this.$nextTick(() => {
              this.$refs['inputForm'].clearValidate()
            })
            let basicName = item.forName ? item.forName : item.basicName
            if (item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length) {
              var checkText = (rule, value, callback) => {
                if (item.minLength && value && value.length < item.minLength * 1) {
                  callback(new Error(basicName + '字数必须大于或等于' + item.minLength));
                } else {
                  callback()
                }
              };
              this.$set(this.rules, item.code, [
                {
                  required: item.dataRequired == '0' ? true : false,
                  message: '请输入' + basicName,
                  trigger: 'blur'
                },
                {validator: checkText, trigger: 'blur'}
              ])
            }
            if (item.archivesBasicMultiselectList.length) {
              this.$set(this.rules, item.code, [
                {
                  required: item.dataRequired == '0' ? true : false,
                  message: '请选择' + basicName,
                  trigger: 'blur'
                },
              ])
            }
            if (item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double') {
              var checkInteger = (rule, value, callback) => {
                if (item.archivesBasicTypeCode == 'double' && value && !this.validate.isPrice(value)) {
                  callback(new Error('请输入正确的' + basicName));
                } else if (item.minLength && value && value * 1 < item.minLength * 1) {
                  callback(new Error(basicName + '必须大于或等于' + item.minLength));
                } else if (item.maxLength && value && value * 1 > item.maxLength * 1) {
                  callback(new Error(basicName + '必须小于或等于' + item.maxLength));
                } else {
                  callback();
                }
              };
              this.$set(this.rules, item.code, [
                {
                  required: item.dataRequired == '0' ? true : false,
                  message: '请输入' + basicName,
                  trigger: 'blur'
                },
                {validator: checkInteger, trigger: 'blur'}
              ])
            }
            if (item.archivesBasicTypeCode == 'date') {
              var checkDate = (rule, value, callback) => {
                const regDate = /^(\d{4})(\d{2})(\d{2})$/;
                if (item.minLength && value && new Date(value.replace(regDate, "$1-$2-$3")) < new Date(item.minLength.replace(regDate, "$1-$2-$3"))) {
                  callback(new Error(basicName + '必须大于或等于' + item.minLength));
                } else if (item.maxLength && value && new Date(value.replace(regDate, "$1-$2-$3")) > new Date(item.maxLength.replace(regDate, "$1-$2-$3"))) {
                  callback(new Error(basicName + '必须小于或等于' + item.maxLength));
                } else {
                  callback();
                }
              };
              this.$set(this.rules, item.code, [
                {
                  required: item.dataRequired == '0' ? true : false,
                  message: '请选择' + basicName,
                  trigger: 'blur'
                },
                {validator: checkDate, trigger: 'blur'}
              ])
            }
            this.loading = false
          })
          if (type == 1) {
            Object.keys(this.inputForm).forEach(key => {
              this.inputForm[key] = this.ruleInformation.archivesDataJSONObject[key]
            })
          } else if (type == 2) {
            Object.keys(this.inputForm).forEach(key => {
              this.inputForm[key] = this.ruleInformation.archivesData[key]
            })
            this.logoutInfo = this.ruleInformation.collectionDataCopy
          }
        }
      })
    },

    getSelectData() {
      this.$axios(this.api.collection.listLevelSelect).then(data => {
        if (data.status) {
          this.leaveList = data.data
          this.leaveList2 = data.data
        }
      })
      this.$axios(this.api.collection.listSelect).then(data => {
        if (data.status) {
          this.typeList = data.data
        }
      })
    },

    // 获取元数据类型设置输入正则
    getNumType(item) {
      let regular = /[^\d]/g
      if (item.archivesBasicTypeCode == "double") {
        regular = /[^\d.]/g
      }
      this.$set(this.inputForm, item.code, this.inputForm[item.code].replace(regular, ''))
    },

    // 浮点数保留两位小数
    verifyNum(item) {
      if (item.archivesBasicTypeCode == "double") {
        let val = this.inputForm[item.code]
        let pointNum = val.toString().split(".").length - 1
        if (val && !this.validate.isPrice(val) && pointNum <= 1) {
          let num = (val * 1).toFixed(2)
          this.$set(this.inputForm, item.code, num)
        }
      }
    },

    //预览
    pagePreview() {
      this.$refs['ruleInformation'].validate((valid) => {
        this.$refs['inputForm'].validate((valid2) => {
          if (valid && valid2) {
            this.inputDisable = true
          } else {
            this.selectTab = 0
            console.log('error submit!!');
            return false;
          }
        });
      });
    },

    //上一步
    backDate() {
      this.inputDisable = false
    },

    // type 1提交  2保存为草稿
    saveAsDraft(type) {
      let entryState = 0;
      if (this.typePage == 'ledgerReview') {
        entryState = 1
      }
      if (this.typePage == 'accountExamine') {
        entryState = 2
      }
      this.ruleInformation.archivesData = this.inputForm
      let datalad = {
        id: this.id,
        entryState: entryState,
        ...this.ruleInformation,
        oneType:this.ruleInformation.archivesBasicDataId,
        collectionDataCopy: this.logoutInfo,
        files:this.$refs.proves.proveDataList,
      }
      if (type == 2) {
        this.$axios(this.api.collection.draftSave, datalad, 'post').then((res) => {
          if (res.status) {
            this.$message.success('保存成功！')
            this.programExit = true
            this.returnPage(1)
          } else {
            this.$message.error(res.msg);
          }
        })
      } else {
        this.$confirm('您是否确认提交数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let fetchUrl = this.api.collection.submit
          if (this.typePage == 'ledger' || this.typePage == 'auxiliaryAccount') {
            fetchUrl = this.api.collection.updateCollectionDataUnderReview
          }
          this.$axios(fetchUrl, datalad, 'post').then((res) => {
            if (res.status) {
              this.$message.success('提交成功！')
              this.programExit = true
              this.returnPage(1)
            } else {
              this.$message.error(res.msg);
            }
          })
        })
      }
    },

    //征集保存
    saveColl() {
      this.$set(this.ruleInformation, 'files', this.$refs.proves.proveDataList)
      this.ruleInformation.archivesData = this.inputForm
      this.ruleInformation.collectionDataCopy = this.logoutInfo
      this.ruleInformation.oneType = this.ruleInformation.archivesBasicDataId
      let pageAllData = JSON.parse(sessionStorage.getItem('pageAllData'))
      if (pageAllData.setindex == 0 || pageAllData.setindex) {
        //修改
        pageAllData.allDataList[pageAllData.setindex] = this.ruleInformation
      } else {
        //新增
        pageAllData.allDataList.push(this.ruleInformation)
      }
      sessionStorage.setItem('pageAllData', JSON.stringify(pageAllData))
      this.programExit = true
      this.$router.push({
        path: '/collection/collect/addRegistration',
        query: {butType: pageAllData.butType, haveData: 'true'}
      })
    },

    //1为上一条 0为下一条
    switchData(num) {
      if (this.butType == 5 || this.butType == 7) {
        if (num == 1) {
          if (this.pageAllData.setindex + 1 == 1) {
            this.$message.error('已是第一条')
          } else {
            this.pageAllData.setindex--
            this.getZhengJi(this.pageAllData.allDataList[this.pageAllData.setindex])
          }
        } else {
          if (this.pageAllData.setindex + 1 >= this.pageAllData.allDataList.length) {
            this.$message.error('已是最后一条')
          } else {
            this.pageAllData.setindex += 1
            this.getZhengJi(this.pageAllData.allDataList[this.pageAllData.setindex])
          }
        }
      } else {
        if (Object.keys(this.registrationSearch).length == 0) {
          this.registrationSearch = JSON.parse(sessionStorage.getItem('registrationSearch'))
        }
        let frechUrl = this.api.collection.listCollectionRevdInfoNextOrPre
        if (this.typePage != 'toExamine' && this.typePage != 'ledgerReview' && this.typePage != 'accountExamine') {
          frechUrl = this.api.collection.listCollectionInfoNextOrPre
        }
        this.$axios(frechUrl, {
          nextInfo: num,
          ...this.registrationSearch
        }, 'post').then((res) => {
          if (res.status) {
            if (res.data) {
              this.id = res.data.id
              this.ruleInformation.archivesBasicDataId = res.data.archivesBasicDataId
              if (num == 0) {
                this.registrationSearch.viewCurrent++
              } else {
                this.registrationSearch.viewCurrent--
              }
              this.$nextTick(()=>{
                this.$refs.proves.getPicture()
                if (this.selectTab == 1) { //图片
                  this.$refs.pictures.getPicture(1)
                }
                if (this.selectTab == 7) { //三维
                  this.$refs.threes.getPicture(1)
                }
                if (this.selectTab == 2) { //音频
                  this.$refs.audioFrequency.getPicture(1)
                }
                if (this.selectTab == 3) { //视频
                  this.$refs.videoS.getPicture(1)
                }
                if (this.selectTab == 4) { //文献
                  this.$refs.literatures.getDataList()
                }
                if (this.selectTab == 5) { //鉴定资料
                  this.$refs.appraisals.getDataList()
                }
                if (this.selectTab == 8) { //修复资料
                  this.$refs.repairs.getDataList()
                }
                if (this.selectTab == 9) { //消毒资料
                  this.$refs.disinfects.getDataList()
                }
                if (this.selectTab == 10) { //装裱资料
                  this.$refs.mountings.getDataList()
                }
                if (this.selectTab == 11) { //保护资料
                  this.$refs.protect.getDataList()
                }
                if (this.selectTab == 12) { //事故资料
                  this.$refs.accident.getDataList()
                }
                if (this.selectTab == 13 || this.selectTab == 14 || this.selectTab == 15) { //事故资料
                  this.$refs.publications.getDataList()
                }
              })
              this.getTreeData()
            } else {
              this.$message.error(`已是${num == 1 ? '第一' : '最后'}一条`);
            }
          } else {
            this.$message.error(res.msg);
          }
        })
      }
    },

    //返回
    returnPage(num) {
      this.returnPageStatus = num
      let pageAllData = JSON.parse(sessionStorage.getItem('pageAllData'))
      if (this.butType == 5 || this.butType == 6) {
        this.$router.push({
          path: '/collection/collect/addRegistration',
          query: {butType: pageAllData.butType, haveData: 'true'}
        })
      } else {
        this.$router.back()
      }
    },

    //去藏品业务中的详情页
    gotoOtherDetail(rote){
      this.returnPageStatus = 1
      this.$router.push(rote)
    },

    //num 3为通过 2为驳回
    thread(num) {
      let revdId
      if (this.butType != 8) {
        if (this.typePage == 'ledgerReview') {
          revdId = 1
        }
        if (this.typePage == 'toExamine') {
          revdId = 0
        }
        if (this.typePage == 'accountExamine') {
          revdId = 2
        }
      } else {
        revdId = 3
      }
      this.$refs.suggestions.init(2, [this.ruleInformation], num, revdId)
    },

    //
    collectionTypeSelect() {
      if (this.ruleInformation.collectionType == 1) {
        this.ruleInformation.generalNum = ''
      }
      if (this.ruleInformation.collectionType != 3) {
        this.$refs.logoutInfo.resetFields()
      }
      if (this.ruleInformation.collectionType == 1 || this.ruleInformation.collectionType == 4){
        this.ruleInformation.collectionLevel = ''
        this.leaveList = this.leaveList2
      }else {
        this.ruleInformation.collectionLevel = ''
        this.leaveList = this.leaveList2.filter(item=>{return item.level == 0})
      }
    },

    setPageTitle() {
      let name = '藏品详情'
      if (this.butType == 0) {
        name = '藏品新增'
      } else if (this.butType == 1) {
        name = '藏品详情'
      } else if (this.butType == 2) {
        name = '藏品修改'
      }

      if (this.typePage == 'ledgerReview') {
        name = '总账审核详情'
      }
      this.$store.state.tagsView.visitedViews.forEach(item => {
        if (item.fullPath === this.$route.fullPath) {
          item.title = name
          return
        }
      })
    },

    handleClick() {
    },

    setTabData() {
      this.tabsList = [
        {
          name: '基本信息',
          value: '0',
        },
      ]

      if (this.id && this.butType != 0 && this.butType != 5) {
        if (this.hasPermissionButton(`allDetail:picture`)) {
          this.tabsList.push(
              {
                name: '图片',
                value: '1',
              },
          )
        }
        if (this.hasPermissionButton(`allDetail:three`)) {
          this.tabsList.push(
              {
                name: '三维模型',
                value: '7',
              },
          )
        }
        if (this.hasPermissionButton(`allDetail:audio`)) {
          this.tabsList.push(
              {
                name: '音频',
                value: '2',
              },
          )
        }
        if (this.hasPermissionButton(`allDetail:video`)) {
          this.tabsList.push(
              {
                name: '视频',
                value: '3',
              },
          )
        }
        if (this.hasPermissionButton(`allDetail:prove`)) {
          this.tabsList.push(
              {
                name: '证明资料',
                value: '6',
              },
          )
        }

        if (this.hasPermissionButton(`allDetail:literature`)) {
          this.tabsList.push(
              {
                name: '文献资料',
                value: '4',
              },
          )
        }
        if (this.hasPermissionButton(`allDetail:appraisal`)) {
          this.tabsList.push(
              {
                name: '鉴定资料',
                value: '5',
              },
          )
        }
        this.tabsList.push(
            {
              name: '修复资料',
              value: '8',
            },
            {
              name: '消毒资料',
              value: '9',
            },
            {
              name: '装裱资料',
              value: '10',
            },
            {
              name: '保护资料',
              value: '11',
            },
            {
              name: '事故资料',
              value: '12',
            },
            {
              name: '出版物数据',
              value: '13',
            },
            {
              name: '文件资料',
              value: '14',
            },
            {
              name: '其它资源',
              value: '15',
            },
        )
      }
      if (this.butType == 5 || this.butType == 6) {
        if (this.hasPermissionButton(`allDetail:prove`)) {
          this.tabsList.push(
              {
                name: '证明资料',
                value: '6',
              },
          )
        }
      }
    },
  },

//离开此页面
  beforeRouteLeave(to, from, next) {
    // 在这里编写逻辑来决定是否允许离开
    // 如果允许离开，调用 next() 方法
    // 如果不允许离开，调用 next(false) 方法
    if (!this.returnPageStatus) {
      sessionStorage.removeItem('listSearch')
      sessionStorage.removeItem('repairListSearch')
    }
    if (this.programExit) {
      this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
      })
      next();
    } else if (this.butType == 0 || this.butType == 2 || this.butType == 5 || this.butType == 6) {
      this.$confirm('当前数据未保存，您确定退出吗？', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        //进去别的页面
        if (this.returnPageStatus) {
          this.$store.dispatch('tagsView/delView', this.$route).then(({visitedViews}) => {
          })
        }
        next();
      }).catch(() => {
        next(false);
      });
    } else {
      next();
    }
  }
  ,
}
</script>

<style scoped>
.contens {
  height: calc(100vh - 240px);
  overflow-y: auto;
  overflow-x: hidden;
}

.minTitle {
  font-weight: bold;
  font-size: 14px;
}

.baseTip {
  text-align: center;
  height: calc(100vh - 450px);
  line-height: calc(100vh - 450px);
}
</style>